import {UnknownWorkflowFilterKey} from './internal.error'
import {ZubehoerAddonEntry} from '../classes/model/component/extras/zubehoer/zubehoerAddonEntry'
import {Dienstleistung} from '../classes/model/component/extras/mehrpreis/dienstleistung'
import {Massblatt} from '../classes/model/component/extras/massblatt'
import {MehrpreisEntry} from '../classes/model/component/extras/zubehoer/mehrpreisEntry'

const filterSplitter = /\[\s*(?<key>[a-zA-Z0-9_-]+)\s*(?:=\s*(?<value>[a-zA-Z0-9_-]+))?\s*]/

const filterFinder = new RegExp(filterSplitter, 'g')

const metaCharacters = {
  isSet: '*',
  not: '!',
} as const

const supportedDienstleistungKeys: readonly string[] = ['Typ', 'Id'] satisfies (keyof ZubehoerAddonEntry  )[]
const supportedMassblattKeys: string[] = ['Id'] satisfies (keyof Massblatt)[]
const supportedMehrpreisKeys: string[] = ['Typ'] satisfies (keyof MehrpreisEntry)[]

const buildPredicates = <T>(
  selectorPart: string,
  isFilterKeyValid: ((key: unknown) => key is keyof T),
  workflowKey?: string
): (UnknownWorkflowFilterKey[]) | (((t: T) => boolean)[]) => {
  const predicates: ((t: T) => boolean)[] = []
  const errors: UnknownWorkflowFilterKey[] = []
  selectorPart.match(filterFinder)?.forEach((encodedFilter): void => {
    const filterParts = encodedFilter.match(filterSplitter)
    const key = filterParts.groups.key
    if (isFilterKeyValid(key)) {
      if (filterParts.groups.value === undefined) {
        // which undefined logic to use here? 'in', 'typeof undefined', 'hasOwnProperty'
        predicates.push((m: T): boolean => typeof m[key] !== 'undefined')
      } else {
        predicates.push(
          (m: T): boolean => String(m[key]).toLowerCase() === filterParts.groups.value.toLowerCase()
        )
      }
    } else {
      errors.push(new UnknownWorkflowFilterKey(key, workflowKey))
    }
  })
  return errors.length !== 0 ? errors : predicates
}

const isSupportedDienstleistungKey = (key: unknown): key is (keyof ZubehoerAddonEntry & keyof Dienstleistung) =>
  typeof key === 'string' && supportedDienstleistungKeys.includes(key)

const isSupportedMassblattKey = (key: unknown): key is (keyof Massblatt) =>
  typeof key === 'string' && supportedMassblattKeys.includes(key)

const isSupportedMehrpreisKey = (key: unknown): key is keyof MehrpreisEntry =>
  typeof key === 'string' && supportedMehrpreisKeys.includes(key)

export default Object.freeze({
  buildPredicates,
  filterFinder,
  filterSplitter,
  isSupportedDienstleistungKey,
  isSupportedMassblattKey,
  isSupportedMehrpreisKey,
  metaCharacters,
} as const)
