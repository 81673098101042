import {Component, EventEmitter, Input, Output} from '@angular/core'
import {NavigatorService} from '../../../classes/service/navigator.service'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {ModalService} from '../../modal/modal.service'
import {
  ZUBEHOER_CATEGORY,
  ZubehoerCategoryIdentifier,
  ZubehoerCategoryName
} from '../../../classes/model/component/extras/zubehoer/zubehoer-category'
import {Tuer} from '../../../classes/model/component/construction/tuer'
import {Seitenteil} from '../../../classes/model/component/construction/seitenteil'
import {Oberlicht} from '../../../classes/model/component/construction/oberlicht'
import {ComponentModel} from '../../../classes/model/component/model/component-model'
import {fbsMehrpreis} from '../../../classes/model/component/extras/mehrpreis/fbsMehrpreis'
import {ConfiguratedDoor} from '../../../classes/model/configuratedDoor'
import {Zubehoer} from '../../../classes/model/component/extras/zubehoer/zubehoer'
import {MehrpreisEntry} from '../../../classes/model/component/extras/zubehoer/mehrpreisEntry'
import {Dienstleistung} from '../../../classes/model/component/extras/mehrpreis/dienstleistung'
import {ComponentSelectionService} from '../../../classes/service/componentSelectionService'
import {ConfiguratorModeService} from '../../../classes/service/configuratorMode.service'
import {ConfiguratorMode} from '../../../types'
import {Massblatt} from '../../../classes/model/component/extras/massblatt'
import {ZubehoerAddonEntry} from '../../../classes/model/component/extras/zubehoer/zubehoerAddonEntry'
import {TranslateService} from '../../../translate'
import {SimpleConstruction} from '../../../classes/model/component/other/construction'
import {ConstructionComponentType} from '../../../classes/model/component/construction/construction-component-type.enum'

const TRANSLATION_KEY = {
  MEHRPREIS_MENU_BOX_SELECTION_CHOICE_LABEL: 'MehrpreisMenuComponent.Box.Input.Label',
  MEHRPREIS_MENU_BOX_SELECTION_CLEAR: 'MehrpreisMenuComponent.Box.Input.Clear',
  MEHRPREIS_MENU_BOX_NO_SELECTION_CHOOSE_PREFIX: 'MehrpreisMenuComponent.Box.SelectMehrpreis.Prefix',
  MEHRPREIS_MENU_BOX_NO_SELECTION_CHOOSE_SUFFIX: 'MehrpreisMenuComponent.Box.SelectMehrpreis.Suffix',
  MEHRPREIS_MENU_ADDON_DATASHEET_LABEL: 'MehrpreisMenuComponent.Addon.DataSheet.Label',
  MEHRPREIS_MENU_BOX_HOUSENUMBER_LABEL: 'MehrpreisMenuComponent.Box.Housenumber.Label',
  MEHRPREIS_MENU_BOX_HOUSENUMBER_TITLE: 'MehrpreisMenuComponent.Box.Housenumber.Title',
  MEHRPREIS_MENU_NO_ACCESSORIES_FOR_DOOR_TEXT: 'MehrpreisMenuComponent.NoAccessories.Door.Text',
  MEHRPREIS_MENU_NO_ACCESSORIES_FOR_SIDEPANEL_TEXT: 'MehrpreisMenuComponent.NoAccessories.Sidepanel.Text',
  MEHRPREIS_MENU_NO_ACCESSORIES_FOR_FANLIGHT_TEXT: 'MehrpreisMenuComponent.NoAccessories.Fanlight.Text'
}

@Component({
  selector: 'configurator-mehrpreis-menu',
  templateUrl: './mehrpreis-menu.component.html',
  styleUrls: ['./mehrpreis-menu.component.scss']
})
export class MehrpreisMenuComponent {
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  protected readonly ZUBEHOER_CATEGORY_HAUSNUMMER = ZUBEHOER_CATEGORY.STREET_NUMBER
  @Input() isMobile: boolean
  @Output() readonly renderChange: EventEmitter<void> = new EventEmitter<void>()

  constructor(
    private _navigatorService: NavigatorService,
    private _configuratorConfigurationModel: ConfiguratorConfigurationModel,
    public readonly modalService: ModalService,
    private componentSelection: ComponentSelectionService,
    private configuratorModeService: ConfiguratorModeService,
    private _translate: TranslateService
  ) {
  }

  categoryHasAddOns(model: ComponentModel, category: string): boolean {
    return model.Mehrpreise.find((m): boolean => m.Typ === category).Dienstleistungen.length > 0
  }

  categoryHasItems(category: string): boolean {
    return typeof this.selectedComponent.model.findZubehoer(category) !== 'undefined'
  }

  categoryName(category: string): string {
    return this._translate.translate(TRANSLATION_KEY.MEHRPREIS_MENU_BOX_NO_SELECTION_CHOOSE_PREFIX)
      + this._translate.translate(this.zubehoerCategoryString(category))
      + this._translate.translate(TRANSLATION_KEY.MEHRPREIS_MENU_BOX_NO_SELECTION_CHOOSE_SUFFIX)
  }

  clearHausnummer(): void {
    const zubehoerChange = this.selectedComponent.updateHausnummer('')
    void this._configuratorConfigurationModel.emitZubehoerChange(zubehoerChange)
  }

  dienstleistungPossible(dienstleistung: Dienstleistung): boolean {
    const componentKonstruktion: SimpleConstruction = this.componentSelection.selectedComponent.konstruktion
    return componentKonstruktion === SimpleConstruction.Einsatz && dienstleistung.IsEinsatzMoeglich
      || componentKonstruktion === SimpleConstruction.AufsatzEinseitig && dienstleistung.IsAufsatzEinseitigMoeglich
      || componentKonstruktion === SimpleConstruction.AufsatzBeidseitig && dienstleistung.IsAufsatzBeidseitigMoeglich
  }

  getDienstleistungen(typ: string): Dienstleistung[] {
    return this.getMehrpreis(typ)?.Dienstleistungen ?? []
  }

  getMehrpreis(typ: string): fbsMehrpreis {
    return this.selectedComponent.model.Mehrpreise.find((m): boolean => m.Typ === typ)
  }

  getZubehoerEntry(categoryName: string): MehrpreisEntry {
    return this.selectedComponent.getZubehoerEntry(categoryName)
  }

  massblattModal(massblaetter: Massblatt[], addon: ZubehoerAddonEntry): void {
    this.modalService.showMassblattModal(massblaetter, addon)
      .afterClosed()
      .subscribe((massblatt: Massblatt): void => {
        if (massblatt.Render) {
          this.renderChange.emit()
        }
      })
  }

  mehrpreisPossible(mehrpreis: fbsMehrpreis): boolean {
    const componentKonstruktion: SimpleConstruction = this.componentSelection.selectedComponent.konstruktion
    return componentKonstruktion === SimpleConstruction.Einsatz && mehrpreis.IsEinsatzMoeglich
      || componentKonstruktion === SimpleConstruction.AufsatzEinseitig && mehrpreis.IsAufsatzEinseitigMoeglich
      || componentKonstruktion === SimpleConstruction.AufsatzBeidseitig && mehrpreis.IsAufsatzBeidseitigMoeglich
  }

  mehrpreisePossible(): boolean {
    const selectedIndex = this.selectedComponent.Index
    const keyInternal = this.configuratedDoor?.Grundform?.KeyIntern?.split('')?.[selectedIndex]
    return keyInternal && keyInternal === this.selectedComponent.shortObjectType
  }

  noAccessoriesText(): string {
    let noAccessoriesText: string = ''
    switch (this.selectedComponent.objectType) {
      case ConstructionComponentType.Door:
        noAccessoriesText = TRANSLATION_KEY.MEHRPREIS_MENU_NO_ACCESSORIES_FOR_DOOR_TEXT
        break
      case ConstructionComponentType.Fanlight:
        noAccessoriesText = TRANSLATION_KEY.MEHRPREIS_MENU_NO_ACCESSORIES_FOR_FANLIGHT_TEXT
        break
      case ConstructionComponentType.SidePanel:
        noAccessoriesText = TRANSLATION_KEY.MEHRPREIS_MENU_NO_ACCESSORIES_FOR_SIDEPANEL_TEXT
        break
    }
    return this._translate.translate(noAccessoriesText)
  }

  setShowToggle(show: NavigatorService['showToggle']): void {
    this._navigatorService.setShowToggle(show)
  }

  showMehrpreis(category: string): void {
    this.modalService.showMehrpreisModal(category)
      .afterClosed()
      .subscribe((zubehoer): void => {
        if (zubehoer) {
          this.toggleZubehoer(zubehoer, true)
        }
      })
  }

  toggleZubehoer(zubehoer: Zubehoer, additionalChecks: boolean = false): void {
    this._configuratorConfigurationModel.toggleZubehoer(zubehoer, additionalChecks)
  }

  toggleZubehoerAddon(zubehoerCategory: string, addonTyp: string): void {
    void this._configuratorConfigurationModel.toggleZubehoerAddon(zubehoerCategory, addonTyp)
  }

  zubehoerCategoryString(categoryIdentifier: ZubehoerCategoryIdentifier): ZubehoerCategoryName {
    return this.selectedComponent.model.getZubehoerCategoryName(categoryIdentifier)
  }

  get configuratedDoor(): ConfiguratedDoor {
    return this._configuratorConfigurationModel.configuratedDoor
  }

  get configuratorMode(): ConfiguratorMode {
    return this.configuratorModeService.mode
  }

  get hausnummer(): string {
    return this.selectedComponent.getHausnummerValue()
  }

  set hausnummer(hausnummer: string) {
    const zubehoerChange = this.selectedComponent.updateHausnummer(hausnummer)
    void this._configuratorConfigurationModel.emitZubehoerChange(zubehoerChange)
  }

  get navService(): NavigatorService {
    return this._navigatorService
  }

  get selectedComponent(): Tuer | Seitenteil | Oberlicht {
    return this.componentSelection.selectedComponent
  }

  get sortedMehrpreise(): fbsMehrpreis[] {
    return this.selectedComponent.model.Mehrpreise.sort((a, b): number => a.Sort - b.Sort)
  }
}
