import {ConfiguratedDoor, ConfiguratedDoorFactory} from './configuratedDoor'
import {EventEmitter, Injectable} from '@angular/core'
import {ParameterService} from '../service/parameter/parameter.service'
import {ConfiguratorMode, Din, InsideOutsideObject, Oeffnungsart, SideType, SimpleIdentifier} from '../../types'
import {ConfiguratedDoorDto} from '../api/configuratedDoor.dto'
import {HttpService} from '../../http.service'
import {GrundformService} from '../service/grundform.service'
import {Deckschicht} from './color/deckschicht'
import {ColorBase} from './color/colorBase'
import {ColorizableComponent} from './color/colorizableComponent'
import {Pulver} from './color/pulver'
import {Zubehoer} from './component/extras/zubehoer/zubehoer'
import {ConfiguratorModeService} from '../service/configuratorMode.service'
import {Material} from './material'
import {HausfrontService} from '../service/hausfront.service'
import {ModelConfigurationService} from '../service/modelConfiguration.service'
import {ComponentSelectionService} from '../service/componentSelectionService'
import {Beschichtung} from './color/beschichtung'
import {NGXLogger} from 'ngx-logger'
import ZubehoerChangeEvent from './event/zubehoer-change.event'
import {ZubehoerCategoryName} from './component/extras/zubehoer/zubehoer-category'
import {Grundform, GrundformTupel} from './component/other/grundform'
import {ConstructionComponent} from './component/construction/constructionComponent'
import {isSimpleConstruction, SimpleConstruction} from './component/other/construction'
import {Lack} from './color/lack'
import {Folie} from './color/folie'
import ThenableEventEmitter from '../event/rxjs/thenable-event-emitter'
import {Paket} from './component/other/paket'
import {DesignOberflaeche} from './color/design'
import ColorRetainApplicator from '../color-retain-applicator/color-retain-applicator'
import {Profile, ProfilMasse} from '../../class'
import {DinUtil} from '../util/dinUtil'
import {ComponentModel} from './component/model/component-model'
import {ConstructionComponentType} from './component/construction/construction-component-type.enum'
import {GlasaufbauFactory} from './component/glassaufbau/glasaufbau'
import {ProfileService} from '../service/profile.service'
import AddonChangeEvent from './event/addon-change.event'

type ModelSetResult = true | 'MaterialMismatch'

@Injectable()
export class ConfiguratorConfigurationModel {
  private _configuratedDoor: ConfiguratedDoor
  readonly afterZubehoerChanged: EventEmitter<void>
  readonly colorChanged: EventEmitter<void>
  readonly configuratedDoorOpened: ThenableEventEmitter<void>
  readonly constructionVariationChanged: EventEmitter<void>
  readonly fillingMaterialChange: EventEmitter<Material>
  readonly grundformChange: EventEmitter<GrundformTupel>
  readonly modelsLoadedAfterGrundformChange: EventEmitter<void>
  readonly newConfiguratedDoorInitialized: ThenableEventEmitter<void>
  readonly securityPackageChange: EventEmitter<void>
  readonly systemMaterialChange: EventEmitter<Material>
  readonly thermalInsulationPackageChange: EventEmitter<void>
  readonly zMassChanged: EventEmitter<void>
  readonly zubehoerAddonChanged: ThenableEventEmitter<AddonChangeEvent>
  readonly zubehoerChanged: ThenableEventEmitter<ZubehoerChangeEvent>

  constructor(
    private httpService: HttpService,
    private configuratedDoorFactory: ConfiguratedDoorFactory,
    private parameterService: ParameterService,
    private grundformService: GrundformService,
    private hausfrontService: HausfrontService,
    private componentSelection: ComponentSelectionService,
    private configuratorModeService: ConfiguratorModeService,
    private modelConfigurationService: ModelConfigurationService,
    private logger: NGXLogger,
    private readonly glasaufbauFactory: GlasaufbauFactory,
    private readonly profileService: ProfileService
  ) {
    this.afterZubehoerChanged = new EventEmitter<void>()
    this.colorChanged = new EventEmitter<void>()
    this.configuratedDoorOpened = new ThenableEventEmitter<void>()
    this.constructionVariationChanged = new EventEmitter<void>()
    this.fillingMaterialChange = new EventEmitter<Material>()
    this.grundformChange = new EventEmitter<GrundformTupel>()
    this.modelsLoadedAfterGrundformChange = new EventEmitter<void>()
    this.newConfiguratedDoorInitialized = new ThenableEventEmitter<void>()
    this.securityPackageChange = new EventEmitter<void>()
    this.systemMaterialChange = new EventEmitter<Material>()
    this.thermalInsulationPackageChange = new EventEmitter<void>()
    this.zMassChanged = new EventEmitter<void>()
    this.zubehoerAddonChanged = new ThenableEventEmitter<AddonChangeEvent>()
    this.zubehoerChanged = new ThenableEventEmitter<ZubehoerChangeEvent>()
  }

  colorizeAllFluegelrahmen(color: ColorBase,
                           view: SideType,
                           material?: Material): void {
    this._configuratedDoor.Components.forEach((c: ConstructionComponent): void => {
      if (c !== this.componentSelection.selectedComponent) {
        let colorArray: number[] = []
        const colorType: string = color.getType()
        if (colorType === 'farbe') {
          colorArray = c.model.Farben.map((l: Lack): number => l.Id)
        } else if (colorType === 'folie') {
          colorArray = c.model.Folien.map((f: Folie): number => f.Id)
        } else if (colorType === 'pulver') {
          colorArray = c.model.Pulver.map((p: Pulver): number => p.Id)
        }
        if (colorArray.includes(color.Id)) {
          c.Fluegelrahmen?.[view].colorize(color, view, material)
        }
      }
    })
  }

  async createComponents(material?: Material): Promise<void> {
    const oeffnungsart: Oeffnungsart = this.defaultOeffnungsart
    this._configuratedDoor.createComponents(oeffnungsart, material)
    await this.updateZmassValues()
  }

  async emitZubehoerChange(changeEvents: ZubehoerChangeEvent | ZubehoerChangeEvent[] | false | undefined): Promise<void> {
    if (!changeEvents) {
      return
    }
    if (!Array.isArray(changeEvents)) {
      changeEvents = [changeEvents]
    }
    await Promise.all([
      ...changeEvents.reduce(
        (promises, changeEvent): Promise<void>[] => promises.concat(
          ...changeEvent.addonChanges.map((change): Promise<void> => this.zubehoerAddonChanged.emit(change))
        ), [] as Promise<void>[]),
      ...changeEvents.map((changeEvent): Promise<void> => this.zubehoerChanged.emit(changeEvent))
    ])
    this.afterZubehoerChanged.emit()
  }

  fbsSelectColor(
    component: ColorizableComponent<Beschichtung | InsideOutsideObject<Beschichtung>> | 'komplett',
    color: ColorBase,
    view: SideType,
    material?: Material
  ): void {
    this.logger.trace('fbsSelectColor', {component, color, view, material})
    material = material ?? this.componentSelection.selectedComponent.material
    if (typeof component === 'string') {
      // colorize everything
      if (component.indexOf('komplett') === 0) {
        // blendrahmen
        if (color.IsBlendrahmenMoeglich) {
          this.configuratedDoor.Blendrahmen[view]?.colorize(color, view, material)
        }
        this.configuratedDoor.Components.forEach((element): void => {
          const elementColor = element.model.findObjectFromData<Lack | Folie | Pulver | DesignOberflaeche>({
            Id: color.Id,
            Typ: color.getType()
          } as SimpleIdentifier)
          if (!elementColor) {
            return
          }
          // all deckschichten (fuellungen)
          if (!(elementColor instanceof Pulver) || material !== Material.Kunststoff) {
            element.Deckschichten?.[view].forEach(ds => void ds.colorize(elementColor, view, element.material))
          }
          // all selected options
          element.optionen[view]
            .filter((o): boolean => !o.IsNurEinzelnFaerbbar || o.BeschichtungAnpassen
                            || (o.IsOrnamentrahmen && elementColor.IsOrnamentrahmenMoeglich))
            .forEach((o): void => void o.colorize(elementColor, view, element.material))
          element.glasaufbau?.Sprossen?.colorize(elementColor, view, element.material)
          if (color.IsFluegelrahmenMoeglich) {
            element.Fluegelrahmen?.[view]?.colorize(elementColor, view, element.material)
          }
        })
      }
    } else if (component instanceof Deckschicht) {
      // deckschicht
      component.colorize(color, view, material)
      // options
      this.componentSelection.selectedComponent.optionen[view]
        .filter((o): boolean => o.BeschichtungAnpassen)
        .forEach((o): void => {
          o.colorize(color, view, material)
        }) // o.Beschichtung = new Beschichtung(deckschicht.Beschichtung))
    } else if (component.Typ === 'blendrahmen') {
      if (color.IsBlendrahmenMoeglich) {
        component.colorize(color, view, material)
      }
    } else if (component.Typ === 'fluegelrahmen') {
      if (color.IsFluegelrahmenMoeglich) {
        component.colorize(color, view, material)
        if (component.Typ === 'fluegelrahmen') {
          this.colorizeAllFluegelrahmen(color, view, material)
        }
      }
    } else {
      component.colorize(color, view, material)
    }
    this.colorChanged.emit()
  }

  async initializeConfiguratedDoor(material?: Material): Promise<void> {
    this._configuratedDoor = this.configuratedDoorFactory.create()
    if (this.configuratorModeService.mode === ConfiguratorMode.TTK) {
      this.hausfrontService.applyHausfrontenFromGrundform(this._configuratedDoor.Grundform)
    }
    await this.createComponents(material)
  }

  async setComponentModel(
    model: ComponentModel,
    component: ConstructionComponent,
    colorRetainApplicator?: ColorRetainApplicator
  ): Promise<ModelSetResult> {
    this.logger.trace('setComponentModel called', {model, component, colorRetainApplicator})
    let result: ModelSetResult = true
    const configuratorMode = this.configuratorModeService.mode
    const parameterModel = this.parameterService.model
    const configuration = model.Konfiguration.Components[0]
    colorRetainApplicator ??= new ColorRetainApplicator(this.configuratedDoor.Components)
    component.model = model
    component.glasaufbau = this.glasaufbauFactory.create(
      model,
      configuration?.Glasaufbau
    )
    component.Hoehenverteilung = model.Hoehenverteilung
    component.Querfries = model.Querfries
    if (
      component.material === Material.Alu && !model.IsAluMoeglich
            || component.material === Material.Kunststoff && !model.IsKunststoffMoeglich
            || component.material === Material.Glas && !model.IsGlasMoeglich
    ) {
      if (model.IsKunststoffMoeglich) {
        component.material = Material.Kunststoff
      } else if (model.IsAluMoeglich) {
        component.material = Material.Alu
      } else if (model.IsGlasMoeglich) {
        component.material = Material.Glas
      } else {
        this.logger.error('material could not be set.')
      }
      if (this.configuratedDoor.profile.Material !== component.material) {
        result = 'MaterialMismatch'
      }
    }
    component.setConfiguration(configuration, configuratorMode)
    if (component.objectType === ConstructionComponentType.Door) {
      this.configuratedDoor.setBlendrahmenFromConfig(model)
      this.configuratedDoor.Aktion = model.Aktion
      if (model.Konfiguration.ProfilId) {
        this.configuratedDoor.profile = new Profile(
          this.profileService.findById(model.Konfiguration.ProfilId) ?? this.profileService.getDefault()
        )
      }
      this.configuratedDoor.setPaketeFromConfigurationComponent(configuration, model.Pakete)
    }
    component.applyMehrpreiseFromConfiguration(configuration)
    component.resetOptions()
    component.din = DinUtil.fromString(
      parameterModel?.din,
      component.din === Din.Left as number || component.din === Din.Right as number ? component.din : Din.Left
    )
    if (
      typeof component.konstruktion !== 'number'
            || !isSimpleConstruction(component.konstruktion)
            // || this.mustChangeConstruktion(component, model)
    ) {
      if (model.IsEinsatzMoeglich) {
        component.konstruktion = SimpleConstruction.Einsatz
      } else if (model.IsAufsatzEinseitigMoeglich) {
        component.konstruktion = SimpleConstruction.AufsatzEinseitig
      } else if (model.IsAufsatzBeidseitigMoeglich) {
        component.konstruktion = SimpleConstruction.AufsatzBeidseitig
      }
    }
    if (component.objectType === ConstructionComponentType.Door) {
      if (configuratorMode === ConfiguratorMode.FBS) {
        const profilMasse = this.configuratedDoor.profile.Masse.find(
          (m: ProfilMasse): boolean => m.Typ === component._objectType
        )
        if (component.konstruktion === SimpleConstruction.Einsatz) {
          component.breite = Math.abs(parameterModel?.bxa - parameterModel?.bxb)
                        + profilMasse.BlendrahmenAussenSeite * 2
                        + profilMasse.FluegelrahmenAussenSeite * 2 // blendrahmen 2x + flügel 2x
          component.hoehe = Math.abs(parameterModel?.bya - parameterModel?.byb)
                        + profilMasse.BlendrahmenAussenOben + profilMasse.FluegelrahmenAussenOben
                        + profilMasse.FluegelrahmenAussenUnten // blendrahmen 1x + flügel 2x
        } else {
          component.breite = Math.abs(parameterModel?.soxb - parameterModel?.soxa)
                        + (profilMasse.FluegelrahmenAussenSeite * 2) // blendrahmen 2x
          component.hoehe = Math.abs(parameterModel?.soyb - parameterModel?.soya) + profilMasse.BlendrahmenAussenOben // blendrahmen 1x
        }
      } else {
        component.breite = this.configuratedDoor?.Hausfronten?.Outside?.TuerBreite
                    || this.configuratedDoor?.Hausfronten?.Inside?.TuerBreite
                    || component.breite
        component.hoehe = this.configuratedDoor?.Hausfronten?.Outside?.TuerHoehe
                    || this.configuratedDoor?.Hausfronten?.Inside?.TuerHoehe
                    || component.hoehe
      }
      await this.updateZmassValues()
      // Set Deckschichten
    }
    colorRetainApplicator.applyColors(component)
    component.glasaufbau.updateGlasaufbau(configuration?.Glasaufbau)
    return result
  }

  async setSecurityPackage(securityPackage: Paket): Promise<void> {
    this.configuratedDoor.sicherheitsPaket = securityPackage
    // ab Sicherheitspaket 2 sollen FLACHRAHMEN UND ALUNOX abgewählt werden
    if (['SI2', 'SI3'].includes(this.configuratedDoor.sicherheitsPaket?.Key)) {
      this._configuratedDoor.Components.forEach((component): void =>
        component.optionen.Inside.filter(
          (option): boolean => option.Gruppe.includes('FLACHRAHMEN') || option.Gruppe.includes('ALUNOX')
        ).forEach((option): void => component.toggleOption(option))
      )
      await this.updateDeckschichtStaerke()
    }
    this._configuratedDoor.Components.forEach((component): void => component.glasaufbau.setSicherheitsGlaeser())
    this.securityPackageChange.emit()
  }

  toggleZubehoer(zubehoer: Zubehoer, additionalChecks: boolean = false): void {
    void this.emitZubehoerChange(this.componentSelection.selectedComponent.toggleZubehoer(zubehoer, additionalChecks))
  }

  async toggleZubehoerAddon(zubehoerCategory: ZubehoerCategoryName, addonTyp: string): Promise<void> {
    const zubehoerChanged = this.componentSelection.selectedComponent.toggleZubehoerAddon(zubehoerCategory, addonTyp)
    if (!zubehoerChanged) {
      return
    }
    const addon = this.componentSelection.selectedComponent.getZubehoerEntry(zubehoerCategory)?.getAddon(addonTyp)
    if (typeof addon?.Massblatt !== 'undefined') {
      await this.updateMassblaetter()
    }
    await this.emitZubehoerChange(zubehoerChanged)
  }

  async updateConstruction(): Promise<void> {
    // this._configuratedDoor.Konstruktion = construction
    this._configuratedDoor.updateKonstruktionsMasse()
    await this.updateZmassValues()
  }

  updateDeckschichtStaerke(): Promise<void> {
    return new Promise<void>((resolve, reject): void => {
      this.httpService.fbsGetDeckschichtStaerke(this.configuratedDoorDto).subscribe({
        next: (data): void => {
          if (typeof data.StaerkeAussen === 'number' && typeof data.StaerkeInnen === 'number') {
            this._configuratedDoor.DeckschichtStaerken = data as {
              StaerkeAussen: number
              StaerkeInnen: number
            }
            resolve()
          } else {
            reject()
          }
        },
        error: reject
      })
    })
  }

  updateMassblaetter(): Promise<void> {
    return new Promise<void>((resolve, reject): void => {
      this.httpService.validateMassblaetter(this.configuratedDoorDto).subscribe({
        // TODO: add change event
        next: data => void this.configuratedDoor.Components.forEach(
          component => void component.Mehrpreise.forEach(
            mehrpreis => void mehrpreis.addons?.forEach(
              addon => void addon.updateMassblatt(data)
            )
          )
        ),
        error: reject,
        complete: resolve
      })
    })
  }

  async updateZmassValues(): Promise<boolean> {
    if (!this.componentSelection?.selectedComponent?.model) {
      return Promise.resolve(true)
    }
    return this.updateDeckschichtStaerke()
      .then((): boolean => {
        let minZmass: number
        let maxZmass: number
        if (this.configuratorModeService.mode === ConfiguratorMode.FBS) {
          minZmass = this.parameterService.model?.minstaerke > 0 ? this.parameterService.model.minstaerke : 0
          maxZmass = this.parameterService.model?.maxstaerke > 0 ? this.parameterService.model.maxstaerke : 120
          if (this.componentSelection.selectedComponent.model) {
            switch (this.componentSelection.selectedComponent.konstruktion) {
              case SimpleConstruction.Einsatz:
                minZmass = Math.max(this.componentSelection.selectedComponent.model.MinStaerkeEinsatz, minZmass)
                break
              case SimpleConstruction.AufsatzEinseitig:
                if (
                  typeof this.parameterService.model?.minsheeto !== 'undefined'
                                    || typeof this.parameterService.model?.maxsheeto !== 'undefined'
                ) {
                  minZmass = minZmass - Math.max(this.parameterService.model?.minsheeto, this.parameterService.model?.maxsheeto)
                  minZmass = Math.max(this.componentSelection.selectedComponent.model.MinStaerkeAufsatzEinseitig, minZmass)
                  maxZmass = maxZmass - Math.max(this.parameterService.model?.minsheeto, this.parameterService.model?.maxsheeto)
                } else {
                  minZmass = Math.max(this.componentSelection.selectedComponent.model.MinStaerkeAufsatzEinseitig, minZmass)
                }
                break
              case SimpleConstruction.AufsatzBeidseitig:
                if (
                  (
                    typeof this.parameterService.model?.minsheeto !== 'undefined'
                                        || typeof this.parameterService.model?.maxsheeto !== 'undefined'
                  ) && (
                    typeof this.parameterService.model?.minsheeti !== 'undefined'
                                        || typeof this.parameterService.model?.maxsheeti !== 'undefined'
                  )
                ) {
                  minZmass = minZmass - Math.max(this.parameterService.model?.minsheeto, this.parameterService.model?.maxsheeto)
                  minZmass = minZmass - Math.max(this.parameterService.model?.minsheeti, this.parameterService.model?.maxsheeti)
                  minZmass = Math.max(this.componentSelection.selectedComponent.model.MinStaerkeAufsatzBeidseitig, minZmass)
                  maxZmass = maxZmass - Math.max(this.parameterService.model?.minsheeto, this.parameterService.model?.maxsheeto)
                  maxZmass = maxZmass - Math.max(this.parameterService.model?.minsheeti, this.parameterService.model?.maxsheeti)
                } else {
                  minZmass = Math.max(this.componentSelection.selectedComponent.model.MinStaerkeAufsatzBeidseitig, minZmass)
                }
                break
            }
          }
        } else /* if (this.configuratorModeService.mode === ConfiguratorMode.TTK) */ {
          minZmass = maxZmass = this.componentSelection.selectedComponent.konstruktion === SimpleConstruction.Einsatz
            ? this.configuratedDoor.profile.ZMassEinsatz
            : this.componentSelection.selectedComponent.konstruktion === SimpleConstruction.AufsatzEinseitig
              ? this.configuratedDoor.profile.ZMassAufsatzEinseitig
              : this.configuratedDoor.profile.ZMassAufsatzBeidseitig
        }
        if (minZmass > maxZmass) {
          maxZmass = minZmass
        }
        this._configuratedDoor.MinZMass = minZmass
        this._configuratedDoor.MaxZMass = maxZmass
        this.componentSelection.selectedComponent.Zmass = this.componentSelection.selectedComponent.Zmass || minZmass
        this.zMassChanged.emit()
        return true
      }).catch((error): boolean => {
        this.logger.error(error)
        return false
      })
  }

  get configuratedDoor(): ConfiguratedDoor {
    return this._configuratedDoor
  }

  get configuratedDoorDto(): ConfiguratedDoorDto {
    return ConfiguratedDoorDto.fromConfiguratedDoor(
      this._configuratedDoor,
      this.parameterService.parameter,
      this.parameterService.model?.pos
    )
  }

  get defaultOeffnungsart(): Oeffnungsart {
    return this.modelConfigurationService.modelConfiguration.tuertyp !== 'HTA' ? 'innen' : 'aussen'
  }

  get fbsMaterial(): Material {
    return this._configuratedDoor?.profile?.Material ?? this.parameterService?.model?.material
  }

  set grundform(grundform: Grundform) {
    if (!grundform || this.configuratedDoor?.Grundform?.Key === grundform.Key) {
      return
    }
    const prevGrundform: Grundform = this.configuratedDoor?.Grundform?.Key ? this.configuratedDoor.Grundform : undefined
    this.grundformService.grundform = grundform
    if (!this.configuratedDoor) {
      return
    }
    this.configuratedDoor.Grundform = grundform
    this.configuratedDoor.bauform = grundform.Key
    this.configuratedDoor.updateComponents(prevGrundform, grundform)
    this.componentSelection.updateSelectedIndex()
    this.grundformChange.emit({Previous: prevGrundform, Current: grundform})
  }

  get material(): Material {
    if (this.configuratorModeService.mode === ConfiguratorMode.FBS) {
      return this.fbsMaterial
    } else if (this.configuratorModeService.mode === ConfiguratorMode.TTK) {
      return this.ttkMaterial
    }
  }

  set material(value: Material) {
    this._configuratedDoor.profile.Material = value
    this.parameterService.model.material = value
    this.systemMaterialChange.emit(value)
  }

  get securityPackage(): Paket {
    return this.configuratedDoor.sicherheitsPaket
  }

  set securityPackage(securityPackage: Paket) {
    void this.setSecurityPackage(securityPackage)
  }

  get selectedComponent(): ConstructionComponent {
    return this.componentSelection.selectedComponent
  }

  get thermalInsulationPackage(): Paket {
    return this.configuratedDoor.waermeschutzPaket
  }

  set thermalInsulationPackage(thermalInsulationPackage: Paket) {
    this.configuratedDoor.waermeschutzPaket = thermalInsulationPackage
    this.configuratedDoor.Components.forEach((c): void => c.glasaufbau.setWaermeschutzGlaeser())
    this.thermalInsulationPackageChange.emit()
  }

  get ttkMaterial(): Material {
    return this._configuratedDoor?.profile?.Material ?? Material.Kunststoff
  }

  /* private mustChangeConstruktion(component: ConstructionComponent, model: ComponentModel): boolean {
                    if (this.configuratorModeService.mode === ConfiguratorMode.TTK) {
                      if (!model.constructionPossible(component.singleConstruction(SideType.Outside), SideType.Outside)
                        || !model.constructionPossible(component.singleConstruction(SideType.Inside), SideType.Inside)) {
                        return true
                      }
                    }
                    return false
                  } */
}
