<div appCustomerStyle></div>
<header>


  <ng-template
    *ngIf='this.configuratorMode === ConfiguratorMode.TTK'
    [ngTemplateOutlet]='headerttk'
  ></ng-template>

</header>
<div (click)="setShowNextToggle('')" [class.active]='showNextToggle' class='modalCover'>
</div>
<div id='mainwrapper'>

  <app-cookie-consent
    (saveAndClose)='hideAndReloadConsent()'
    [imprintUrl]="settings.ImpressumUrl ?? 'https://www.rodenberg.ag/de/unternehmen/impressum' "
    [show]='cookieConsentService.showConsentBanner'
  ></app-cookie-consent>

  <nav id='mainNavigation'>
    <div (click)='this.navService.toggleNav()' [class.active]='this.navService.mobileNav' [class.mobile]='isMobile' class='top'>

      <div *ngIf='isMobile' class='logowrapper'>
        <img [src]='logoUrl$ | async' alt='Logo' class='logo'>
      </div>

      <span>{{ this.navigatorService.navigationEntries?.[showToggle]?.title ?? (TRANSLATION_KEY.MENU_TITLE | translate) }}</span>
    </div>
    <div class='bottom'>
            <span class='link'>
                <a (click)='this.showSupport(); false' href='#'>{{ TRANSLATION_KEY.CONFIGURATOR_NAVIGATION_SUPPORT | translate }}</a>
            </span>
      <span class='link'>
                <a (click)='this.showPrivacyDialog(); false' href='#'>{{ TRANSLATION_KEY.CONFIGURATOR_NAVIGATION_PRIVACY | translate }}</a>
            </span>
      <span class='link'>
                <a
                  [href]="settings.ImpressumUrl ?? 'https://www.rodenberg.ag/de/unternehmen/impressum'"
                  target='_blank'
                >
                    {{ TRANSLATION_KEY.CONFIGURATOR_NAVIGATION_IMPRINT | translate }}
                </a>
            </span>
      <span (click)='cookieConsentService.showConsentBanner = true' *ngIf='isMobile' class='link consentToggle'>
                {{ TRANSLATION_KEY.CONFIGURATOR_NAVIGATION_COOKIES | translate }}
            </span>
    </div>
    <ul [class.active]='this.navService.mobileNav' [class.fbs]='this.configuratorMode === ConfiguratorMode.FBS'>

      <ng-container *ngFor='let navkey of this.navigatorService.menuEntries, let i = index;'>
        <li [attr.data-showtoggle]='navkey'>
          <a
            (click)='this.navigatorService.setShowToggle(navkey)'
            [class.active]='showToggle === navkey'
            [class.disabled]='!this.navigatorService.activateNav(navkey)'
          >
            <i [class]='this.navigatorService.navigationEntries[navkey].icon'></i>
            <span>{{ this.navigatorService.navigationEntries[navkey].title }}</span>
          </a>
        </li>
      </ng-container>

      <li class='only-mobile'>
        <a (click)='this.showPrivacyDialog(); false' href='#'>{{ TRANSLATION_KEY.MOBILE_MENU_PRIVACY_LINK_LABEL | translate }}</a>
        <a *ngIf='settings.ImpressumUrl' [href]='settings.ImpressumUrl' target='_blank'>
          {{ TRANSLATION_KEY.CONFIGURATOR_NAVIGATION_IMPRINT | translate }}
        </a>
        <span (click)='cookieConsentService.showConsentBanner = true' class='link consentToggle'>
                    {{ TRANSLATION_KEY.CONFIGURATOR_NAVIGATION_COOKIES | translate }}
                </span>
      </li>
    </ul>

  </nav>

  <div #contentWrapper class='contentWrapper' id='contentWrapper'>
    <ng-container *ngIf='result_outside'>

      <ng-container *ngIf='!configuratedDoor.Hausfronten[this.view]'>
        <div class='doorImages'>

          <ng-container
            *ngIf='this.configuratorMode === ConfiguratorMode.FBS
                                || this.configuratorMode === ConfiguratorMode.TTK && this.view === SideType.Outside'
          >
            <div
              (click)='this.view = SideType.Outside' [class.active]='this.view === SideType.Outside'
              class='outside'
            >
              <img [src]='result_outside'>
              <p>{{ TRANSLATION_KEY.VIEW_OUTSIDE | translate }} <i class='fa fa-check'></i></p>
            </div>
          </ng-container>

          <ng-container
            *ngIf='this.configuratorMode === ConfiguratorMode.FBS
                            || this.configuratorMode === ConfiguratorMode.TTK && this.view === SideType.Inside'
          >
            <div
              (click)='this.view = SideType.Inside' [class.active]='this.view === SideType.Inside'
              class='inside'
            >
              <img [src]='result_inside'>
              <p>{{ TRANSLATION_KEY.VIEW_INSIDE | translate }} <i class='fa fa-check'></i></p>
            </div>
          </ng-container>

        </div>
      </ng-container>

      <ng-container *ngIf='hausfrontMenuComponent && configuratedDoor.Hausfronten[this.view]'>

        <div
          (change)='this.recalcMobileHeight()'
          [ngStyle]="{
                        'background-image': 'url(' + hausfrontMenuComponent.backgroundImage + ')',
                        'background-size': '100%',
                        'height': hausfrontMenuComponent.offsetHeight + 'px'
                     }"
          class='background-image-blur blur'
        ></div>

        <img
          (load)='hausfrontMenuComponent.onBackgroundLoaded()' [src]='hausfrontMenuComponent.backgroundImage'
          id='image_background'
        >

        <door-view
          *ngIf='doorPosition && this.configuratedDoor?.Images?.[this.view]'
          [heightBackground]='doorPosition.backgroundHeight' [heightDoor]='doorPosition.doorHeight'
          [urlDoor]='this.configuratedDoor.Images[this.view]'
          [widthBackground]='doorPosition.backgroundWidth'
          [widthDoor]='doorPosition.doorWidth'
          [x0]='doorPosition.offsettedX0'
          [x1]='doorPosition.offsettedX1'
          [x2]='doorPosition.offsettedX2'
          [x3]='doorPosition.offsettedX3'
          [y0]='doorPosition.y0'
          [y1]='doorPosition.y1'
          [y2]='doorPosition.y2' [y3]='doorPosition.y3'
          id='image_door'
        >
        </door-view>


      </ng-container>


    </ng-container>


    <div
      [class.active]='this.loading || (
                this.configuratorMode === ConfiguratorMode.TTK && hausfrontMenuComponent?.loadingBackground
             )'
      class='loading-door-overlay-wrapper'
    >
      <div class='loading-door-overlay'>
        <i aria-hidden='true' class='fa fa-cog fa-spin fa-fw fa-3x'></i>
        <p>{{ TRANSLATION_KEY.LOADING_PLEASE_WAIT | translate }}...</p>
      </div>
    </div>


    <div *ngIf='this.isMobile && this.configuratedModelsLoaded()' class='frontOptions mobile'>

      <button [configurator-fullscreen-toggle]='contentWrapper' [isMobile]='this.isMobile' class='fullscreenButton'>
      </button>

      <button
        (click)='this.memoListService.has(parameter?.tuerId)
                            ? this.memoListService.removeCurrentDoor()
                            : this.memoListService.addCurrentDoor()' class='favoriteButton'
      >
        <span>{{ this.memoListService.length }}</span>
        <i class='icon-favorite'></i>
      </button>

      <button (click)='this.shareDialog()' class='shareButton'>
        <i class='fa fa-share-alt'></i>
      </button>

      <button (click)='this.postMultivision()' *ngIf='this.settings.Multivision' class='multivisionButton'>
        <i class='fa fa-television'></i>
      </button>

      <button (click)='changeDoorView(this.view === SideType.Inside ? SideType.Outside : SideType.Inside)' class='viewButton'>
        <i class='icon-view-mobile'></i>
      </button>
    </div>

    <ng-container *ngIf='!this.configuratedModelsLoaded()'>
      <div *ngIf="this.configuratorMode === ConfiguratorMode.FBS && isDevMode() && parameter?.token === '123'">
        <ul
          [class.active]='showNextToggle'
          class='frontOptions desktop'
        >
          <li>
            <configurator-load-configuration-button
              (loadRequest)='loadComponentByIndex($event)'
            >
            </configurator-load-configuration-button>
          </li>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngIf='this.configuratedModelsLoaded()'>
      <div>
        <ul
          *ngIf='this.configuratorMode === ConfiguratorMode.FBS' [class.active]='showNextToggle'
          class='frontOptions desktop'
        >
          <li [configurator-fullscreen-toggle]='contentWrapper' [isMobile]='this.isMobile'></li>

          <li
            (click)="setShowNextToggle('calculateFbs')"
            *ngIf='this._componentSelection.selectedComponent && this._componentSelection.selectedComponent.model'
            [class.active]="showNextToggle === 'calculateFbs'"
          >
            <i class='fa fa-calculator'></i>
            <p>{{ TRANSLATION_KEY.CONFIGURATOR_FRONTOPTIONS_PRICE | translate }}</p>
          </li>

          <li (click)='showCharacteristics = !showCharacteristics'>
            <i class='fa fa-list-ul'></i>
            <p>{{ TRANSLATION_KEY.CONFIGURATOR_FRONTOPTIONS_CHARACTERISTICS | translate }}</p>
          </li>

          <li *ngIf="this.configuratorMode === ConfiguratorMode.FBS && isDevMode() && parameter?.token === '123'">
            <configurator-load-configuration-button
              (loadRequest)='loadComponentByIndex($event)'
            >
            </configurator-load-configuration-button>
          </li>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngIf='this.configuratorMode === ConfiguratorMode.TTK && !this.isMobile'>
      <ul [class.active]='showNextToggle' class='frontOptions desktop'>
        <li [configurator-fullscreen-toggle]='contentWrapper' [isMobile]='this.isMobile'></li>

        <li
          (click)='this.memoListService.has(parameter?.tuerId)
                            ? this.memoListService.removeCurrentDoor()
                            : this.memoListService.addCurrentDoor()'
        >
          <div class='icon'>
            <i
              [class.fa-heart-o]='!this.memoListService.has(parameter?.tuerId)'
              [class.fa-heart]='this.memoListService.has(parameter?.tuerId)'
              class='fa fa-heart-o'
            ></i>
          </div>
          <p>{{ TRANSLATION_KEY.CONFIGURATOR_FRONTOPTIONS_ADDMEMO | translate }} ({{ this.memoListService.length }})</p>
        </li>

        <li (click)='showCharacteristics = !showCharacteristics'>
          <i class='fa fa-list-ul'></i>
          <p>{{ TRANSLATION_KEY.CONFIGURATOR_FRONTOPTIONS_CHARACTERISTICS | translate }}</p>
        </li>

        <li (click)='this.postMultivision()' *ngIf='this.settings.Multivision'>
          <i class='fa fa-television'></i>
          <p>{{ TRANSLATION_KEY.CONFIGURATOR_FRONTOPTIONS_MULTIVISION | translate }}</p>
        </li>

      </ul>

    </ng-container>

    <div
      *ngIf='this.selectedComponent' [class.mobile]='isMobile' [style.height.px]='isMobile ? this.recalcMobileHeight(): null'
      class='subnav'
    >

      <configurator-load-n-search-menu
        (loadRequest)='loadDoorById($event)'
        (searchRequest)="modelMenuComponent.modelTerm = $event; navigatorService.setShowToggle('Modell')"
        [isMobile]='isMobile'
      ></configurator-load-n-search-menu>

      <configurator-hausfront-menu
        (doorPositionChange)='this.doorPosition=$event'
        (renderRequest)='this.getCompleteDoor()'
        [isMobile]='isMobile'
        [lazyImageLoaded]='lazyImageLoaded.bind(this)'
      ></configurator-hausfront-menu>

      <configurator-grundform-menu
        (renderRequest)='this.getCompleteDoor()'
        [isMobile]='isMobile'
        [lazyImageLoaded]='lazyImageLoaded.bind(this)'
      >
        >
      </configurator-grundform-menu>

      <configurator-catalogue-menu
        [isMobile]='isMobile'
        [lazyImageLoaded]='lazyImageLoaded.bind(this)'
        [modelMenu]='modelMenuComponent'
      ></configurator-catalogue-menu>

      <configurator-model-menu
        (selectModel)='onSelectModel($event.Id, $event.Typ)'
        [isMobile]='isMobile'
      ></configurator-model-menu>

      <configurator-basis-menu
        (constructionChange)='this.updateZmassValues(); this.clearFbsKonstruktion(); this.getCompleteDoor()'
        (elementDinChange)='this.getCompleteDoor()'
        (elementMaterialChange)='this.onMaterialChange($event)'
        (klebesystemChange)='this.updateZmassValues()'
        (oeffnungsartChange)='this.updateOeffnungsart()'
        (systemDinChange)='this.updateOeffnungsart()'
        (systemMaterialChange)='this.changeMaterialTuersystem($event)'
        (zMassChange)='this.onZmassChange()'
        [isMobile]='isMobile'
      ></configurator-basis-menu>

      <configurator-key-data-menu
        (renderRequest)='this.getCompleteDoor()'
        [isMobile]='isMobile'
      >
      </configurator-key-data-menu>

      <configurator-request-menu
        (loadRequest)='loadDoorById($event)'
        [isMobile]='isMobile'
      >
      </configurator-request-menu>


      <configurator-colors-menu
        (viewChange)='changeView($event)'
        [isMobile]='isMobile'
      ></configurator-colors-menu>

      <configurator-glasses-menu
        (glassaufbauChange)='getCompleteDoor()'
        [isMobile]='isMobile'
      ></configurator-glasses-menu>


      <configurator-options-menu
        (optionChange)='onOptionChange()'
        (viewChange)='changeView($event)'
        [isMobile]='isMobile'
      ></configurator-options-menu>

      <configurator-mehrpreis-menu
        (renderChange)='getCompleteDoor()'
        [isMobile]='isMobile'
      ></configurator-mehrpreis-menu>

    </div>

    <ng-template [ngTemplateOutlet]='viewSwitch'></ng-template>
  </div><!-- /mainWrapper -->

  <configurator-characteristics-menu
    (closeMenu)='showCharacteristics = false'
    *ngIf='showCharacteristics'
    class='subnav'
  ></configurator-characteristics-menu>

  <div
    *ngIf='this.configuratedDoor && this.configuratorMode === ConfiguratorMode.FBS'
    [class.active]="showNextToggle === 'calculateFbs'"
    [class.hidden]="showNextToggle !== 'calculateFbs'"
    class='frontView calculate'
  >
    <div class='header'>
      <h2>{{ TRANSLATION_KEY.CALC_MODAL_TITLE | translate }}</h2>
      <span (click)="setShowNextToggle('')" class='close'></span>
    </div>
    <div class='content'>
      <div class='row'>
        <div class='column'>

          <ng-template [ngTemplateOutlet]='uValues'></ng-template>


          <ng-container *ngIf='this.configuratedDoor.summary.dataArray.length > 0'>
            <h1>{{ TRANSLATION_KEY.CALC_MODAL_TABLE_COLUMN_SUMMARY | translate }}</h1>
            <table [dataSource]='configuratedDoor.summary.dataArray' class='summary' mat-table>

              <!-- Category Column -->
              <ng-container matColumnDef='Category'>
                <th
                  *matHeaderCellDef
                  mat-header-cell
                >{{ TRANSLATION_KEY.CALC_MODAL_TABLE_COLUMN_ASSIGNMENT | translate }}
                </th>
                <td *matCellDef='let element' mat-cell> {{ element.Category | translate }}</td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef='Prefix'>
                <th *matHeaderCellDef mat-header-cell>{{ TRANSLATION_KEY.CALC_MODAL_TABLE_COLUMN_PREFIX | translate }}</th>
                <td *matCellDef='let element' mat-cell> {{ element.Prefix | translate }}</td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef='Titel'>
                <th
                  *matHeaderCellDef
                  mat-header-cell
                >{{ TRANSLATION_KEY.CALC_MODAL_TABLE_COLUMN_TITLE | translate }}
                </th>
                <td *matCellDef='let element' mat-cell> {{ element.Titel | translate }}</td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef='Text'>
                <th
                  *matHeaderCellDef
                  mat-header-cell
                >{{ TRANSLATION_KEY.CALC_MODAL_TABLE_COLUMN_TEXT | translate }}
                </th>
                <td *matCellDef='let element' mat-cell> {{ element.Text | translate }}</td>
              </ng-container>

              <tr
                *matHeaderRowDef="
                                    configuratedDoor.summary.getCategoryCount() < 2
                                        ? ['Prefix','Titel','Text']
                                        : ['Category', 'Prefix','Titel','Text']
                                "
                mat-header-row
              ></tr>
              <tr
                *matRowDef="
                                    let emprow;
                                    columns: configuratedDoor.summary.getCategoryCount() < 2
                                        ? ['Prefix','Titel','Text']
                                        : ['Category', 'Prefix','Titel','Text']
                                "
                mat-row
              ></tr>
            </table>
          </ng-container>
          <ng-container *ngIf='this.configuratedDoor?.summary?.dataArray?.length === 0'>
            <p class='loadingInformation'>
              {{ TRANSLATION_KEY.LOADING_CONFIGURATION | translate }} <i class='fa fa-2x fa-spin fa-spinner'></i>
            </p>
          </ng-container>


        </div>

        <div class='column'>

          <div class='scrollWrapper'>

            <div class='row'>
              <div class='column'>
                <h1>{{ TRANSLATION_KEY.CALC_MODAL_INPUT_KOMMISSION_TITLE | translate }}</h1>
                <p class='kommission'>
                  <input
                    [(ngModel)]='this.configuratedDoor.Kommission'
                    [value]="parameter.kommission ?? ''"
                  >
                </p>
              </div>
              <div class='column'>
                <h1>{{ TRANSLATION_KEY.CALC_MODAL_INPUT_ACTION_TITLE | translate }}</h1>
                <p class='aktion'>
                  <input
                    (keyup)='this.delayedCalculation()'
                    [(ngModel)]='this.configuratedDoor.Aktion'
                    [value]='this.configuratedDoor.Aktion'
                  >
                </p>
              </div>
            </div>

            <h1>{{ TRANSLATION_KEY.CALC_MODAL_PRICES_TITLE | translate }}</h1>

            <div *ngIf='this.configuratedDoor.Prices' class='priceDataWrapper'>

              <table [dataSource]='this.configuratedDoor.Prices.Data' class='prices' mat-table>

                <!-- Name Column -->
                <ng-container matColumnDef='nr'>
                  <th
                    *matHeaderCellDef
                    mat-header-cell
                  >{{ TRANSLATION_KEY.CALC_MODAL_PRICES_TABLE_COLUMN_NR | translate }}
                  </th>
                  <td *matCellDef='let element' mat-cell> {{ element.Nr }}</td>
                </ng-container>
                <ng-container matColumnDef='text'>
                  <th
                    *matHeaderCellDef
                    mat-header-cell
                  >{{ TRANSLATION_KEY.CALC_MODAL_PRICES_TABLE_COLUMN_DESCRIPTION | translate }}
                  </th>
                  <td *matCellDef='let element' mat-cell> {{ element.Text  | translate }}</td>
                </ng-container>
                <!-- Symbol Column -->
                <ng-container matColumnDef='brutto'>
                  <th
                    *matHeaderCellDef
                    mat-header-cell
                  >{{ TRANSLATION_KEY.CALC_MODAL_PRICES_TABLE_COLUMN_BRUTTO | translate }}
                  </th>
                  <td
                    *matCellDef='let element'
                    mat-cell
                  > {{
                      element.PreisBrutto.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR'
                      })
                    }}
                  </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef='rabatt'>
                  <th
                    *matHeaderCellDef
                    mat-header-cell
                  >{{ TRANSLATION_KEY.CALC_MODAL_PRICES_TABLE_COLUMN_DISCOUNT | translate }}
                  </th>
                  <td *matCellDef='let element' mat-cell>
                    {{
                      element.Rabatt !== 0 ? element.Rabatt : ''
                    }}{{

                      element.Rabatt !== null && element.Rabatt !== 0 ? '%' : ''
                    }}
                    <span *ngIf='element.Sonderrabatt !== null && element.Sonderrabatt !== 0'>
                                            + {{ element.Sonderrabatt }}{{ element.Sonderrabatt !== null ? '%' : '' }}
                                        </span>
                  </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef='netto'>
                  <th
                    *matHeaderCellDef
                    mat-header-cell
                  >{{ TRANSLATION_KEY.CALC_MODAL_PRICES_TABLE_COLUMN_NETTO | translate }}
                  </th>
                  <td
                    *matCellDef='let element'
                    mat-cell
                  > {{
                      element.PreisNetto.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR'
                      })
                    }}
                  </td>
                </ng-container>

                <tr *matHeaderRowDef='this.getCalculationColumns()' mat-header-row></tr>
                <tr
                  *matRowDef='let emprow; columns: this.getCalculationColumns() ;'
                  mat-row
                ></tr>
              </table>

            </div>

            <ng-container *ngIf='!this.configuratedDoor.Prices'>
              <p class='loadingInformation'>{{ TRANSLATION_KEY.CALC_MODAL_LOADING_DATA | translate }}<i
                class='fa fa-2x fa-spin fa-spinner'
              ></i></p>
            </ng-container>

            <ng-container *ngIf='this.configuratedDoor.Infos.length > 0'>
              <h1>{{ TRANSLATION_KEY.CALC_MODAL_HINTLIST_HINTS_TITLE | translate }}</h1>

              <div *ngFor='let info of this.configuratedDoor.Infos ' class='configurationInfo'>
                <p class='message'><i class='fa fa-info-circle'></i> {{ info['Message']  | translate }}
                </p>
              </div>
            </ng-container>

            <ng-container *ngIf='this.configuratedDoor.Warnings.length > 0'>
              <h1>{{ TRANSLATION_KEY.CALC_MODAL_HINTLIST_WARNINGS_TITLE | translate }}</h1>

              <div *ngFor='let warning of this.configuratedDoor.Warnings ' class='configurationWarning'>
                <p class='message'><i
                  class='fa fa-exclamation-triangle'
                ></i> {{ warning['Message']  | translate }}</p>
              </div>
            </ng-container>

            <ng-container *ngIf='this.configuratedDoor.Errors.length > 0'>
              <h1>{{ TRANSLATION_KEY.CALC_MODAL_HINTLIST_ERRORS_TITLE | translate }}</h1>

              <div *ngFor='let error of this.configuratedDoor.Errors ' class='configurationError'>
                <p class='message'><i
                  class='fa fa-exclamation-circle'
                ></i> {{ error['Message']  | translate }}</p>
              </div>
            </ng-container>

          </div>


          <table class='buttons'>
            <tr>
              <td>
                <button (click)='showNotesDialog()' class='notesButton'>
                  {{ TRANSLATION_KEY.BUTTON_NOTES | translate }}
                </button>
              </td>
              <td>
                <button
                  (click)='this.saveFbs()'
                  [disabled]='!this.configuratedDoor.Prices'
                  id='sendFBSConfig'
                >
                  <i class='fa fa-floppy-o'></i>
                  <span>{{ TRANSLATION_KEY.BUTTON_SAVE | translate }}</span>
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<footer>
  <span>
    {{
      TRANSLATION_KEY.HINT_MEASURES_NOT_OBLIGING | translate
    }}
  </span>
  <span
    (click)='cookieConsentService.showConsentBanner = true' *ngIf='!isMobile'
    class='link consentToggle'
  >
        {{ TRANSLATION_KEY.FOOTER_ITEMS_COOKIES | translate }}</span>&nbsp;-&nbsp;[{{ TRANSLATION_KEY.FOOTER_ITEMS_VERSION | translate }} {{
    version
  }}]
</footer>

<ng-template #uValues>
  <h1>{{ TRANSLATION_KEY.CALC_MODAL_VALUES_TITLE | translate }}</h1>
  <table class='calcValues'>
    <tr *ngIf='this.configuratedDoor.DoorWeight && this.configuratedDoor.Insulation'>
      <td>
        <p>{{ TRANSLATION_KEY.CALC_MODAL_VALUES_WEIGHT_TITLE | translate }}</p>
      </td>
      <td>
        <p class='weight'>{{ TRANSLATION_KEY.CALC_MODAL_VALUES_WEIGHT_PREFIX | translate }}
          {{ this.configuratedDoor.DoorWeight }}{{ TRANSLATION_KEY.CALC_MODAL_VALUES_WEIGHT_SUFFIX | translate }}</p>
      </td>
      <td>
        <p [innerHTML]='this.getUPString() | safeHtml'></p>
      </td>
      <td>
        <p class='insulation'>{{ this.configuratedDoor.Insulation.Up }}</p>
      </td>
      <td>
        <p [innerHTML]='this.getUGString() | safeHtml'></p>
      </td>
      <td>
        <p class='insulation'>{{ this.configuratedDoor.Insulation.Ug }}</p>
      </td>
    </tr>
    <tr *ngIf='!this.configuratedDoor.DoorWeight || !this.configuratedDoor.Insulation'>
      <td>
        <ng-container *ngIf='!this.configuratedDoor.Prices'>
          <p class='loadingInformation'>{{ TRANSLATION_KEY.CALC_MODAL_LOADING_DATA | translate }}
            <i class='fa fa-2x fa-spin fa-spinner'></i></p>
        </ng-container>
      </td>
    </tr>
  </table>
</ng-template>


<ng-template #headerttk>
  <a [href]='logoLinkUrl' class='logo'>
    <img [src]='logoUrl$ | async' alt='Rodenberg Logo'>
  </a>
  <h1>
    {{ TRANSLATION_KEY.CONFIGURATOR_HEADER_TITLE | translate }}
    <span *ngIf='this.showPrice'>{{ 'Plus' | translate }} </span>
  </h1>
  <a *ngIf='this.showPrice' href='/logout'>
    <i class='headericon logout fa fa-sign-out'></i>
  </a>
  <a (click)="setShowNextToggle('calculation')" *ngIf='this.showPrice'>
    <i [attr.data-content-string]='TRANSLATION_KEY.HEADER_TTK_SETTINGS | translate' class='headericon calc fa fa-cogs'></i>
  </a>
</ng-template>

<ng-template #viewSwitch>
  <ul *ngIf='this.configuratorMode === ConfiguratorMode.TTK' class='optionAspectList'>
    <li (click)='this.changeView(SideType.Outside)' [class.active]='view === SideType.Outside'>
      <i class='icon-view-l'></i>
      <p>{{ TRANSLATION_KEY.BUTTON_VIEWSWITCH_OUTSIDE | translate }}</p>
    </li>
    <li (click)='this.changeView(SideType.Inside)' [class.active]='view === SideType.Inside'>
      <i class='icon-view-r'></i>
      <p>{{ TRANSLATION_KEY.BUTTON_VIEWSWITCH_INSIDE | translate }}</p>
    </li>
  </ul>
</ng-template>
